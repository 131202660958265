export default {
  space: [0, '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '8rem', '16rem', '32rem'],
  breakpoints: ['640px', '768px', '1024px', '1280px'],
  fonts: {
    body: 'Open Sans, sans-serif',
    heading: 'Poppins, sans-serif',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 60, 74],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
    nav: 1.5,
  },
  box: {
    white: {
      bg: 'white',
      color: 'text',
      textAlign: 'center',
      h4: {
        color: 'secondary',
      },
      p: {
        color: 'text',
      },
    },
  },
  links: {
    default: {
      fontFamily: 'heading',
      textDecoration: 'none',
    },
    primary: {
      color: 'primary',
      textDecoration: 'none',
      '&.active': {
        color: 'primary',
      },
    },
    nav: {
      color: 'white',
      fontWeight: 'bold',
      textDecoration: 'none',
      borderBottomWidth: '4px',
      borderBottomColor: 'transparent',
      borderBottomStyle: 'solid',
      display: 'block',
      mx: [0, null, null, 4],
      py: [0, null, null, 2],
      p: [3, null, null, 0],
      '&.active': {
        borderBottomColor: 'primary',
        borderBottomStyle: 'solid',
        borderBottomWidth: '4px',
      },
      '&:hover': {
        color: 'lightPurple',
        borderBottomColor: 'lightPurple',
        borderBottomStyle: 'solid',
        borderBottomWidth: '4px',
      },
    },
    dropdown: {
      color: `lightPurple`,
      fontWeight: 'bold',
      textDecoration: 'none',
      mx: 4,

      '&:hover': {
        color: 'white',
      },
    },
    footer: {
      color: 'grey',
      textDecoration: 'none',
      py: 1,
      '&.active': {
        color: 'white',
      },
      '&:hover': {
        color: 'white',
      },
    },
  },
  sections: {
    white: {
      bg: 'white',
      color: 'text',
      h1: {
        color: 'darkPurple',
      },
    },
    transparent: {
      bg: 'transparent',
      color: 'text',
      h1: {
        color: 'darkPurple',
      },
    },
    purple: {
      bg: 'primary',
      color: 'white',
      h1: {
        color: 'white',
      },
      span: {
        color: 'lightPurple',
      },
    },
    dark: {
      bg: 'secondary',
      color: 'white',
      h1: {
        color: 'white',
      },
      h5: {
        color: 'white',
        marginBottom: 1,
      },
      p: {
        color: 'lightPurple',
      },
      span: {
        color: 'lightPurple',
      },
    },
    gradient: {
      backgroundImage: 'linear-gradient(45deg,#4024A4,#2D1876)',
      h1: {
        color: 'white',
      },
      h2: {
        color: 'white',
      },
      h3: {
        color: 'white',
      },
      span: {
        color: 'lightPurple',
      },
      p: {
        color: 'lightPurple',
      },
    },
    radial: {
      backgroundImage: 'radial-gradient(#090615,#100A25)',
      h1: {
        color: 'white',
      },
      h2: {
        color: 'white',
      },
      h3: {
        color: 'white',
      },
      span: {
        color: 'lightPurple',
      },
      p: {
        color: 'lightPurple',
      },
    },
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
      transition: 'all 0.2s ease',
      '&:hover': {
        color: 'primary',
        bg: 'white',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      },
      h1: {
        color: 'white',
      },
    },
    secondary: {
      color: 'white',
      bg: 'secondary',
      '&:hover': {
        color: 'white',
        bg: 'secondary',
      },
    },
  },
  colors: {
    text: '#969696',
    background: '#F9F9F9',
    primary: '#623DE8',
    secondary: '#131516',
    muted: '#f6f6f6',
    lightPurple: '#D1C8FF',
    lightGrey: '#C0C0C0',
    darkPurple: '#181137',
  },
  text: {
    default: {
      color: 'text',
      fontSize: 3,
    },
    heading: {
      color: 'darkPurple',
      fontFamily: 'heading',
      fontWeight: 'heading',
      padding: 0,
      margin: 0,
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      variant: 'text.heading',
      fontSize: [5, 6, 7],
      padding: 0,
      margin: 0,
    },
    h2: {
      variant: 'text.heading',
      fontSize: [4, 5, 6],
    },
    h3: {
      variant: 'text.heading',
      fontSize: [3, 4, 5],
    },
    h4: {
      variant: 'text.heading',
      fontSize: 24,
    },
    h5: {
      variant: 'text.heading',
      fontSize: 18,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 16,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
      fontFamily: 'heading',
      textDecoration: 'none',
      '&:hover': {
        color: 'secondary',
      },
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
}
